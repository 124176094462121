import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrimiiPasiPage = () => (
  <Layout>
    <SEO title="Primii pași în organizarea nunții" />
    <div className="drawer article-content">
      <h1 className="article-title">Primii pași în organizarea nunții</h1>
      <p>
        Înainte de toate, gândește-te la povestea voastră și încearcă să o
        transpui în acea zi specială. Poți transpune fiecare pasiune, fiecare
        preferință sau poti găsi o temă care să reflecte personalitațile
        voastre.
      </p>
      <p>
        Sigur că inițial îți vei imagina cadrul în care se va desfașura, însă
        inainte de alegerea locației ar trebui să știi, cu aproximație, cam cât
        de mare va fi nunta voastră. Alegerea locației depinde de numărul de
        invitați.
      </p>
      <p>
        Urmeaza alegerea locației. Aici este cadrul în care se conturează
        povestea voastră, așadar trebuie să vă gandiți bine la acest aspect.
        Cele mai importante aspecte pentru alegerea localului:
      </p>
      <section>
        <div>
          <h3 className="paragh-title">① Capacitatea localului </h3>
          <p>
            Dacă numarul de invitați depașeste capacitatea localului, mai bine
            caută un spațiu mai mare. De asemenea, dacă vei avea 100 invitați și
            localul este pentru 400, locul va părea gol, oricât te vei strădui
            să umpli spațiul prin decorațiuni.
          </p>
        </div>
        <div>
          <h3 className="paragh-title">
            ② Perioada în care îți vei organiza nunta{" "}
          </h3>
          <p>
            Dacă îți dorești o nuntă toamna poate e puțin riscant să alegi o
            grădină. Dacă nunta este programată iarna este necesar să te
            gândești și la accesul către locație. Oricât de romantic ar suna, nu
            vei puta merge în rochie de mireasă și bocanci către locație, dacă
            drumurile de acces sunt blocate pentru mașini. locatie, daca
            drumurile de acces sunt blocate pentru masini.
          </p>
        </div>
        <div>
          <h3 className="paragh-title">
            ③ Decorul/designul sălii potrivit pentru nunta ta de vis{" "}
          </h3>
          <p>
            Dacă îți dorești o nuntă romantică nu este indicat să alegi un
            restaurant în care predomină decorațiunile extravagante, cadrul
            ideal pentru o astfel de nuntă ar fi un cort sau chiar o nunta în
            aer liber. Poți avea o nuntă rustică, elegantă, vintage, una
            romantică sau una cu o tematica specifică, tot ce contează este sa
            va reprezinte pe voi și povestea voastră de dragoste 😊.
          </p>
        </div>
        <div>
          <h3 className="paragh-title"> ④ Ringul de dans </h3>
          <p>
            Un ring de dans prea mic este întotdeauna o problema, așadar ar fi
            bine sa iei în calcul și acest aspect.
          </p>
        </div>
        <div>
          <h3 className="paragh-title"> ⑤ Interacțiunea cu personalul</h3>
          <p>
            Până la urma veți interacționa foarte mult și ei sunt cei care pot
            influența ca nunta să fie un eveniment reușit. Desigur va fi nevoie
            să te interesezi și despre meniuri, prețurile acestora, dacă de
            băuturi se ocupă cei de la restaurant, dacă colaborează cu anumiți
            furnizori pentru aranajamente florale sau candy-bar.
          </p>
        </div>
      </section>
      <p>
        Deși sunt multe apecte de luat în calcul, cu siguranță unele sunt mai
        importante decât celelalte pentru unii dintre noi, important este să îți
        aloci timp suficient căutărilor și înainte de a semna contractul, să
        clarifici cât mai multe din aspectele organizatorice menționate.
      </p>
      <p>
        Nu îți face griji, când vei păși prima dată în locul respectiv, vei știi
        că acolo ai vrea să celebrați povestea voastră.
      </p>

      <div className="nav-section">
        <Link to="/">◀ Inapoi la pagina principală</Link>
        <Link to="/tematica"> ▶Nunta cu tematica</Link>
      </div>
    </div>
  </Layout>
)

export default PrimiiPasiPage
